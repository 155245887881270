<!-- 轨迹查询页面 -->
<template>
  <div :lang="$i18n.locale" class="track" style="margin-top:100px;text-align:center">
    <h1 style="font-size:32px">{{$t('trackQuery.trackTitle')}}</h1>
    <el-form
      :inline="true"
      :model="formInline"
      class="demo-form-inline"
      style="margin-top:45px"
      @submit.native.prevent
    >
      <el-form-item>
        <el-input
          v-model.trim="formInline.input"
          :placeholder="$t('trackQuery.trackContent')"
          @keyup.enter.native="orderGui"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="orderGui" style="width:108px">{{$t('trackQuery.trackButton')}}</el-button>
      </el-form-item>
    </el-form>
    <!-- 轨迹对话窗 -->
    <!-- <el-dialog
      :title="title"
      :visible.sync="popups"
      width="1100px"
      append-to-body
			:close-on-click-modal="false"
      class="timeline"
      :before-close="handleDialogClose"
    >
      <div style="text-align:right;font-size:18px;color:red">
        <span>{{this.onRemarks}}</span>
        <span v-show="this.isShow">：</span>
        <span>{{this.warehouseTime}}</span>
      </div>
			<div class="dialog_orderNo">
				<label>{{formInline.input}}</label>
			</div>
      <el-timeline :reverse="true">
        <el-timeline-item
          v-for="(activity, index) in activities"
          :key="index"
          color="green"
          :timestamp="(activity.initTime+' '+'Time Zone：Beijing')"
          placement="top"
        >
          <el-card>
            <h4>{{activity.description}}</h4>
            <p v-show="activity.location==false?false:activity.location==null?false:true">
              Location:
              <span style="font-weight:700">{{activity.location}}</span>
            </p>
            <p>
              Remark:
              <span style="font-weight:700">{{activity.remarks}}</span>
            </p>
          </el-card>
        </el-timeline-item>
      </el-timeline>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="viewPOD" v-show="isButton">View POD Photos</el-button>
        <el-button type="primary" @click="cancel">Confirm</el-button>
      </div>
    </el-dialog> -->
		<!-- 轨迹对话窗 -->
		<el-dialog :lang="$i18n.locale" :title="$t('commonInfo.trackingTitle')" :visible.sync="popups" width="880px" append-to-body :close-on-click-modal="false"
			class="timeline" :before-close="handleDialogClose">
			<!-- <div style="text-align:right;font-size:18px;color:red">
				<span>{{this.onRemarks}}</span>
				<span v-show="this.isShow">：</span>
				<span>{{this.warehouseTime}}</span>
			</div> -->
			<div class="dialog_orderNo">
				<label>{{'OrderNo: ' + rowOrderNo}}</label>
			</div>
			<div class="dialog_extraInfo">
				<label v-show="dlvdMainDeliveryTime != ''">{{$t('commonInfo.appointmentTime') + '：' + dlvdMainDeliveryTime}}</label>
				<div class="trackingOpt leave" v-if="leaveTracking != null">
				  <i class="el-icon-ship"></i>
					<span>{{leaveTracking.remarks}}</span>
				  <span>{{leaveTracking.description}}</span>
				</div>
				<div class="trackingOpt arrive" v-if="arriveTracking != null">
				  <i class="el-icon-s-home"></i>
					<span>{{arriveTracking.remarks}}</span>
				  <span>{{arriveTracking.description}}</span>
				</div>
			</div>
			<el-timeline>
				<el-timeline-item v-for="(activity, index) in trackingMainData" :key="index" color="green"
					:timestamp="(activity.initTime)" placement="top" v-show="activity.status==1000?false:true">
					<el-card v-if="activity.status != 'batchInfo'">
						<!-- 轨迹区域 -->
						<h4>{{activity.description}}</h4>
						<!-- location -->
						<p v-show="activity.location==false?false:activity.location==null?false:true">
							{{$t('commonInfo.trackLocation') + ':'}}
							<span style="font-weight:700">{{activity.location}}</span>
						</p>
						<!-- 备注 -->
						<p>
							{{$t('commonInfo.remark') + ':'}}
							<span style="font-weight:700">{{activity.remarks}}</span>
						</p>
					</el-card>
					<!-- 分批信息 -->
					<el-card v-if="activity.status == 'batchInfo'">
					  <el-row style="background-color: #f9f9f9; margin-bottom: 15px;border-radius: 10px;padding-top: 20px;" v-for="(subOrderData,subOrderIndex) in subActiveData" :key="subOrderIndex">
					    <div class="dialog_subOrderNo">
					      <label>{{$t('commonInfo.batch')+': '+subOrderData[0].esnadTobOrderNo}}</label>
								<div style="display: inline-flex;" v-for="(trackItem,trackIndex) in subOrderData" :key="trackIndex">
								  <label style="color: #5981c9;font-size: 16px;margin-left: 150px;" v-if="trackItem.status == '1000'">{{$t('commonInfo.appointmentTime') + '：' + trackItem.description}}</label>
								</div>
					    </div>
					    <!-- 子订单轨迹 -->
					    <el-timeline>
					      <el-timeline-item v-for="(subActivity,subIndex) in subOrderData" :key="subIndex" color="#cbac39"
					      :timestamp="(subActivity.initTime)" placement="top" v-show="activity.status==1000?false:true">
					        <p style="font-size:16px">
					          <span style="font-weight:700">{{subActivity.remarks}}</span>
					        </p>
					        <!-- 轨迹区域 -->
					        <h4 style="color:#666;font-size:16px">
					          [ {{subActivity.location}} ]
					          {{subActivity.description}}
					        </h4>
					      </el-timeline-item>
					    </el-timeline>
					  </el-row>
					</el-card>
				</el-timeline-item>
			</el-timeline>
			<div slot="footer" class="dialog-footer">
				<!-- <el-button type="primary" @click="viewPOD">View POD Photos</el-button> -->
				<el-button type="primary" @click="handleDialogClose">{{$t('commonInfo.dialogBtnConfirm')}}</el-button>
			</div>
		</el-dialog>
    <!-- pod照片弹窗 -->
    <el-dialog
      title="POD Photos"
      :visible.sync="viewPodPhothos"
      width="1100px"
      append-to-body
			:close-on-click-modal="false"
      class="timeline"
    >
      <div
        class="prod-item"
        v-for="(item,index) in dynamicPods"
        :key="index"
        style="width:300px;display:inline;margin:0 10px;"
      >
        <!-- <img :src="item.link" alt style="width:300px;" /> -->

        <el-image
          style="width: 300px; "
          :src="item.link"
          :preview-src-list="srcList"
          :z-index="3000"
        ></el-image>
      </div>
      <div slot="footer" class="dialog-footer">
        <!-- <el-button type="primary" @click="cancel">Confirm</el-button> -->
      </div>
    </el-dialog>
  </div>
</template>
<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      formInline: {
        input: ""
      },
      popups: false,
      trackingList: [],
      title: "DLVD Tracking",
      activities: [],
			mainActiveData:[],//主订单轨迹
			subActiveData:[],//子订单轨迹
			trackingMainData:[],//展示轨迹数组
			orderType:'',//订单类型 A、空运 S、海运 C、B2C小包
			// 轨迹显示修改
			leaveTracking:null,
			arriveTracking:null,
      viewPodPhothos: false,
      dynamicPods: [],
			rowOrderNo:'',
			dlvdMainDeliveryTime:'',
      url: "",
      srcList: [],
      warehouseTime: "",
      onRemarks: "",
      isShow: false,
      isButton: false
    };
  },
  created() {
    let id = this.$route.query.id;
    if (id) {
      this.formInline.input = id;
      this.onSubmit();
    }
  },
  methods: {
    // 订单轨迹查询
    async orderGui() {
      if (this.formInline.input.length === 0) {
        this.popups = false;
        return this.$message.warning(
          //请输入订单号
          this.$t('trackQuery.messageImport')
        );
      }
			// 查询轨迹接口线上地址为7000的服务，
			// 线下接口http://172.18.1.12:8081
			// 线上的为https://vip.dlvd.com，线上需要在/mgr前加上/api
			
			// 线下
			// http://172.18.0.34:7011/mgr/tracking/
			// 线上
			// https://www.dlvd.com/mgr/tracking/
			this.mainActiveData = [];
			this.subActiveData = [];
			this.trackingMainData = [];
			this.leaveTracking = null;
			this.arriveTracking = null;
			const axios = require("axios");
			axios
			  .get(
			    "https://tracking.fleetan.com/mgr/tracking/" +
			      this.formInline.input
			  ).then(res => {
					if (res.code === 200) {
						this.rowOrderNo = this.formInline.input;
						this.activities = res.data;
						this.mainActiveData = this.activities.mainOrderTrackingList;
						this.subActiveData = this.activities.subOrderTrackingList;
						this.orderType = this.activities.trackingType;
						if (this.mainActiveData.length === 0) {
							//输入的订单号不存在
						  return this.$message.error(this.$t('tipsInfo.orderNoExistTips'));
						}
						// 增加分批轨迹信息 如果有返回子订单轨迹则再主订单轨迹入库完成后面添加对象，来显示子订单分批信息 zpy 2022-7-29
						if(this.subActiveData.length > 0){
						  let index = this.mainActiveData.findIndex((item) => item.status == 'n200');
						  this.mainActiveData.splice(index,0,{
						    initTime:this.$t('chinaWhB2BOrderPage.batchTrackingTitle'),
						    status:'batchInfo',
						  })
							this.subActiveData.forEach(item => {
								item.forEach(subItem => {
									subItem.initTime = subItem.initTime + ' Time Zone：Beijing';
								})
								
							})
						}
						
						// 显示预计开船或预计到港需要判断轨迹是否已经存在已起航或到港的真实轨迹
						let trueLeave = this.mainActiveData.filter(item => item.status == '300');
						let trueArrive = this.mainActiveData.filter(item => item.status == '320');
						this.mainActiveData.forEach(item => {
						  if(item.status == '1000'){
						    this.dlvdMainDeliveryTime = item.description
						  }
							if (item.status == 1000 && item.id) {
								this.warehouseTime = item.description;
								this.onRemarks = item.remarks;
								this.isShow = true;
							}
							if(item.initTime != this.$t('chinaWhB2BOrderPage.batchTrackingTitle')){
								item.initTime = item.initTime + ' Time Zone：Beijing';
							}
							// 海运轨迹
							if(item.status == '230'){//预计开船
								if(trueLeave.length == 0){// 已有起航轨迹就不需要显示预计开船时间轨迹
								  this.leaveTracking = item;
								}
							} else if(item.status == '310'){//预计到港
								if(trueArrive.length == 0){// 已有到港轨迹就不需要显示预计到港时间轨迹
								  this.arriveTracking = item;
								}
							}else{
								this.trackingMainData.push(item);
							}
						});
						
						this.popups = true;
					}else{
						this.$message.error(res.msg);
					}
				})
    },
    // 弹出层x号关闭
    handleDialogClose() {
      // alert(1);
      this.popups = false;
      this.isShow = false;
      this.warehouseTime = "";
      this.onRemarks = "";
			this.dlvdMainDeliveryTime = '';
    },
    // 查询POD照片
    async viewPOD() {
      const res = await this.$http.get(
        "/api/mgr/toborder/orderinfo/pod/" + this.formInline.input
      );
      if (res.code == 200) {
        // console.log(res.data);
        this.dynamicPods = res.data;
        this.viewPodPhothos = true;
        let podData = res.data;
        podData.forEach(item => {
          // console.log(item);
          this.srcList.push(item.link);
        });
      } else {
        this.$message.error(res.msg);
      }
    },
    cancel() {
      this.popups = false;
      this.dynamicPod = false;
    },
    onSubmit() {
      console.log(
        "%cDLVD",
        `padding: 10px 40px;
  font-size: 24px;
  font-weight: 600;
  border-radius: 10px;
  background-color:silver;
  background-image:
    radial-gradient(circle at 100% 150%, silver 24%, white 24%, white 28%, silver 28%, silver 36%, white 36%, white 40%, transparent 40%, transparent),
    radial-gradient(circle at 0 150%, silver 24%, white 24%, white 28%, silver 28%, silver 36%, white 36%, white 40%, transparent 40%, transparent),
    radial-gradient(circle at 50% 100%, white 10%, silver 10%, silver 23%, white 23%, white 30%, silver 30%, silver 43%, white 43%, white 50%, silver 50%, silver 63%, white 63%, white 71%, transparent 71%, transparent),
    radial-gradient(circle at 100% 50%, white 5%, silver 5%, silver 15%, white 15%, white 20%, silver 20%, silver 29%, white 29%, white 34%, silver 34%, silver 44%, white 44%, white 49%, transparent 49%, transparent),
    radial-gradient(circle at 0 50%, white 5%, silver 5%, silver 15%, white 15%, white 20%, silver 20%, silver 29%, white 29%, white 34%, silver 34%, silver 44%, white 44%, white 49%, transparent 49%, transparent);
  background-size: 100px 50px;`
      );
    },
    // 格式化日期
    dateFormat: function(time) {
      var date = new Date(time);
      var year = date.getFullYear();
      /* 在日期格式中，月份是从0开始的，因此要加0
       * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
       * */
      var month =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      var hours =
        date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      var minutes =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      var seconds =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      // 拼接
      return (
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds +
        " " +
        "Time Zone：Beijing"
      );
    }
  }
};
</script>
<style scoped>
/deep/.el-input__inner {
  width: 600px !important;
  height: 54px !important;
  border-radius: 10px;
  outline: none;
  border: none;
  border: 2px solid #c4c7ce;
  padding: 10px 48px 10px 16px;
  font-size: 17px;
  margin-left: 116px;
}
.el-input__inner:hover {
  margin-left: 108px;
  border: 2px solid #a7aab5;
}
.el-button {
  background-color: #4e6ef2;
  height: 54px;
  border: 1px solid #4e6ef2;
  outline: none;
  position: relative;
  top: 0;
  right: 108px;
  border-radius: 10px;
  color: #fff;
}
.el-button:hover {
  background-color: #4662d9;
  cursor: pointer;
}
.el-form {
  display: flex;
  justify-content: center;
  align-items: center;
}
.el-form--inline .el-form-item {
  margin-right: 0 !important;
}

.timeline {
  background-color: #efefef;
}
.el-dialog {
  margin-top: 4vh !important;
}
.el-dialog__footer {
  position: relative;
  top: 0;
}
.dialog-footer {
  position: absolute;
  bottom: 15px;
  right: -88px;
}
/deep/.el-dialog {
  margin-top: 2vh !important;
}
.dialog_orderNo {
		width: 100%;
		height: 50px;
		line-height: 26px;
	}

	.dialog_orderNo label {
		float: left;
		margin-left: 40px;
		font-weight: bold;
		border-radius: 8px;
		font-size: 20px;
		padding: 5px 15px;
		border: 1px solid #EBEEF5;
		box-shadow: 0 2px 4px 0 rgb(0 0 0 / 30%);
	}
	.dialog_orderNo label:nth-child(2){
	  float: right;
	  color: #5981c9;
	  font-size: 18px;
	}
	/* 子订单号 */
	.dialog_subOrderNo{
	  width: 100%;
	  height: 40px;
	  line-height: 30px;
	}
	.dialog_subOrderNo label {
	  margin-left: 20px;
	  border-radius: 6px;
	  font-size: 16px;
	  padding: 5px 10px;
	}
	
	.dialog_extraInfo{
		width: 100%;
		padding: 15px 25px;
		margin-bottom: 5px;
	}
	.dialog_extraInfo label{
		margin: 10px 15px;
		font-weight: bold;
		border-radius: 8px;
		color: #5981c9;
		font-size: 18px;
		padding: 5px 15px;
		border: 1px solid #EBEEF5;
		box-shadow: 0 2px 4px 0 rgb(0 0 0 / 30%);
	}
	
	.trackingOpt{
	  display: inline-block;
		width: 500px;
	  margin: 4px 15px;
	  padding: 8px 10px;
	  font-size: 14px;
	  color: #ededed;
	  border-radius: 6px;
	  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 30%);
	}
	.trackingOpt i{
		font-size: 26px;
		display: block;
		float: left;
		width: 40px;
		height: 50px;
		line-height: 50px;
	}
	.trackingOpt span{
		width: 440px;
		line-height: 24px;
		display: block;
		float: left;
	}
	.leave{
	  background-color: #01AAED;
	  border: 1px solid #01AAED;
	}
	.arrive{
	  background-color: #1e8f86;
	  border: 1px solid #1e8f86;
	}
</style>
<style scoped lang="scss">
	// 阿拉伯语
	[lang="ar"] {
		.listHeader{
			i{
				float: right;
			}
			span{
				float: right;
			}
		}
		
		::v-deep .el-form-item__label{
			float: right;
		}
		.el-form-item__content{
			.el-button--primary{
				margin-left: 10px;
			}
		}
		// 表格
		::v-deep .el-table__fixed-right{
			left: 0;
			right: auto;
			.el-table__fixed-body-wrapper{
				left: 0;
				right: auto;
			}
		}
		::v-deep .el-table__fixed-right .el-table__fixed-header-wrapper{
			left: 0;
			right: auto;
		}
		::v-deep .fixed-width .el-button{
			margin: 0 5px 0 0 !important;
			i{
				margin-left: 5px;
			}
		}
		::v-deep .el-dropdown{
			margin: 0 5px 0 0 !important;
			.batchInfoItem{
				width: 20px;
				height: 20px;
				left: -15px;
				right: auto;
				top:-5px;
			}
			span{
				margin-left: -5px;
				float: right;
			}
			i{
				float: right;
				margin: 6px 0 0 5px;
			}
		}
		::v-deep .is-dot {
			left: -2px;
			right: auto;
			top: 6px;
		}
		::v-deep .el-dialog__headerbtn{
			right: auto;
			left: 20px;
		}
		::v-deep .el-dialog__footer {
			text-align: left;
			.el-button {
				margin: 0 10px;
			}
		}
		::v-deep .el-select .el-tag{
			margin: 0 5px;
		}
		::v-deep .el-select .el-tag__close.el-icon-close{
			right: 5px;
		}
		::v-deep .el-tabs__nav{
			float: right;
		}
		.dialog_orderNo {
			label{
				float: right;
				margin-right: 40px;
			}
		}
		::v-deep .el-timeline-item__node--normal{
			right: -15px;
		}
	}
</style>